import React from "react";

class Disclaimer extends React.Component {
    render() {
        return (
            <div className="Content-div">
                <fieldset className="Disclaimer-fieldset">
                    <legend className="Disclaimer-legend">
                        <h1 className="Disclaimer-h1">Disclaimer</h1>
                    </legend>
                    <div className="Disclaimer-div">
                        <p className="Disclaimer-p">
                            The Elder Scrolls Online (ESO) is the property of ZeniMax Online Studios and their publisher Bethesda Softworks. Please contact <a className="Disclaimer-a" href="mailto:mainbuilds@outlook.com">MainBuilds</a> if anything displayed on this website is in violation of copyright and it will be removed.
                        </p>
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default Disclaimer;