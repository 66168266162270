import React from "react";
import $ from 'jquery';

$(document).ready(function () {
    $("#WhyOne-div").hide();
    $("#WhyTwo-div").hide();
    $("#WhyThree-div").hide();
    $("#WhyFour-div").hide();
    $("#WhyFive-div").hide();
});

function toggleDisplay(divInput) {
    $("[id^='Why']").not("#" + divInput).hide();
    var div = document.getElementById(divInput);
    div.style.display = div.style.display === 'block' ? 'none' : 'block';
}

function displayWhyOne() {
    toggleDisplay("WhyOne-div");
}

function displayWhyTwo() {
    toggleDisplay("WhyTwo-div");
}

function displayWhyThree() {
    toggleDisplay("WhyThree-div");
}

function displayWhyFour() {
    toggleDisplay("WhyFour-div");
}

function displayWhyFive() {
    toggleDisplay("WhyFive-div");
}

class Tales extends React.Component {
    render() {
        return (
            <div className="Content-div">
                <fieldset className="Tales-fieldset">
                    <legend className="Tales-legend">
                        <h1 className="Tales-h1">Tales of Tribute (ToT) Top 5 Tips</h1>
                    </legend>
                    <div className="Tales-div">
                        <p className="Tales-p">
                            <span className="Number-span">1.)</span> Convert your 1-power base patron cards to the 2-coin Writs of Coin.
                        </p>
                        <button className="Why-button" onClick={displayWhyOne}>Why?</button>
                        <div id="WhyOne-div">
                            <p className="Why-p">
                                <span className="Why-span">This will give you the best chance at having a minimum of 6 gold on the third hand.</span>
                            </p>
                        </div>
                        <p className="Tales-p">
                            <span className="Number-span">2.)</span> Stick to buying 2 patron deck's cards maximum.
                        </p>
                        <button className="Why-button" onClick={displayWhyTwo}>Why?</button>
                        <div id="WhyTwo-div">
                            <p className="Why-p">
                                <span className="Why-span">You win via card combinations and buying more cards of different types is counter to this.</span>
                            </p>
                        </div>
                        <p className="Tales-p">
                            <span className="Number-span">3.)</span> Kill agents whenever possible.
                        </p>
                        <button className="Why-button" onClick={displayWhyThree}>Why?</button>
                        <div id="WhyThree-div">
                            <p className="Why-p">
                                <span className="Why-span">Letting your opponent keep their agent on the field is the fastest way to lose.</span>
                            </p>
                        </div>
                        <p className="Tales-p">
                            <span className="Number-span">4.)</span> Unlock the additional patron decks and upgrade all cards.
                        </p>
                        <button className="Why-button" onClick={displayWhyFour}>Why?</button>
                        <div id="WhyFour-div">
                            <p className="Why-p">
                                <span className="Why-span">Access to all the decks and cards will help you to learn how to play and counter them.</span>
                            </p>
                        </div>
                        <p className="Tales-p">
                            <span className="Number-span">5.)</span> Have fun! You're going win and lose a lot of games.
                        </p>
                        <button className="Why-button" onClick={displayWhyFive}>Why?</button>
                        <div id="WhyFive-div">
                            <p className="Why-p">
                                <span className="Why-span"> Learn from each lose and don't take this luck-based game too seriously.</span>
                            </p>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default Tales;