import './components/footer/footer.css';
import './components/header/header.css';
import './index.css';
import './pages/builds/builds.css';
import './pages/disclaimer/disclaimer.css';
import './pages/discord/discord.css';
import './pages/home/home.css';
import './pages/submit/submit.css';
import './pages/tales/tales.css';
import './pages/timer/timer.css';
import App from './App.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);