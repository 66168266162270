import React, { useState } from "react";
import * as Skills from "../../skill-library";

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}
function Modal() {
    function Skill(props) {
        return <img class="Skills-img" alt="Skills-img"
            onMouseOverCapture={(event) => handleHover(props.name, event)}
            onClickCapture={(event) => handleHover(props.name, event)}
            onMouseMoveCapture={() => handleHover()}
            onTouchStartCapture={() => handleHover()}
            src={props.src} {...props}>
        </img>;
    }
    const handleHover = (skillName, event) => {
        if (skillName === hoveredSkill)
            return;
        switch (skillName) {
            case "RadiatingRegeneration":
                const FB1 = getOffset(event.target);
                setPosition(FB1);
                setHoveredSkill(Skills.RadiatingRegeneration);
                break;
            case "LingeringFlare":
                const FB2 = getOffset(event.target);
                setPosition(FB2);
                setHoveredSkill(Skills.LingeringFlare);
                break;
            case "PolarWind":
                const FB3 = getOffset(event.target);
                setPosition(FB3);
                setHoveredSkill(Skills.PolarWind);
                break;
            case "EnergyOrb":
                const FB4 = getOffset(event.target);
                setPosition(FB4);
                setHoveredSkill(Skills.EnergyOrb);
                break;
            case "CombatPrayer":
                const FB5 = getOffset(event.target);
                setPosition(FB5);
                setHoveredSkill(Skills.CombatPrayer);
                break;
            case "LifeGiver":
                const FBU = getOffset(event.target);
                setPosition(FBU);
                setHoveredSkill(Skills.LifeGiver);
                break;
            case "DeceptivePredator":
                const BB1 = getOffset(event.target);
                setPosition(BB1);
                setHoveredSkill(Skills.DeceptivePredator);
                break;
            case "ShimmeringShield":
                const BB2 = getOffset(event.target);
                setPosition(BB2);
                setHoveredSkill(Skills.ShimmeringShield);
                break;
            case "IceFortress":
                const BB3 = getOffset(event.target);
                setPosition(BB3)
                setHoveredSkill(Skills.IceFortress);
                break;
            case "BlueBetty":
                const BB4 = getOffset(event.target);
                setPosition(BB4);
                setHoveredSkill(Skills.BlueBetty);
                break;
            case "OverflowingAltar":
                const BB5 = getOffset(event.target);
                setPosition(BB5);
                setHoveredSkill(Skills.OverflowingAltar);
                break;
            case "HealingThicket":
                const BBU = getOffset(event.target);
                setPosition(BBU);
                setHoveredSkill(Skills.HealingThicket);
                break;
            default:
                setHoveredSkill(null);
        }
    };
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const display = hoveredSkill ? "block" : "none";
    return (
        <div className="BC-div">
            <div className="Tooltip-div" style={{ display: display, top: position.top + 232/* , left: position.left */ }}>
                <h1 className="SkillName-h1">{hoveredSkill?.name}</h1>
                <img className="Tooltip-img" src={`${hoveredSkill?.icon}`} alt="Tooltip-img"></img>
                <h1 className="Description-h1">Description:</h1>
                <p className="Tooltip-p" dangerouslySetInnerHTML={{ __html: hoveredSkill?.effect_1 }}></p>
                <h1 className="Morph-h1">Morph:</h1>
                <p className="Morph-p" dangerouslySetInnerHTML={{ __html: hoveredSkill?.effect_2 }}></p>
            </div>
            <div className="IntroductionNecrom-div">
                <div className="Title-div">
                    <h1 className="Title-h1">Magic Warden Heal Mother</h1>
                </div>
                <div className="Top-div">
                    <div className="Creator-div">
                        <h1 className="Creator-h1">Build Creator:</h1>
                        <h1 className="Creator-h1"><a href="https://gamerdvr.com/gamer/warreaper47/videos" target="_blank" rel="noopener noreferrer">War Reaper 47</a></h1>
                    </div>
                    <div className="Patch-div">
                        <h1 className="Patch-h1">Updated for:</h1>
                        <h1 className="Patch-h1">Necrom</h1>
                    </div>
                </div>
            </div>
            <div className="FB-div">
                <fieldset className="FB-fieldset">
                    <legend className="FB-legend">
                        <h1 className="Bar-h1">Frontbar</h1>
                    </legend>
                    <div className="Skills-div">
                        <Skill name="RadiatingRegeneration" src="https://mainbuilds.com/media/skills/weapon/restoration-staff/regeneration/radiating-regeneration.png" alt="radiating-regeneration"></Skill>
                        <Skill name="LingeringFlare" src="https://mainbuilds.com/media/skills/alliance-war/support/revealing-flare/lingering_flare.png" alt="lingering-flare"></Skill>
                        <Skill name="PolarWind" src="https://mainbuilds.com/media/skills/class/warden/winters-embrace/arctic-wind/polar-wind.png" alt="polar-wind"></Skill>
                        <Skill name="EnergyOrb" src="https://mainbuilds.com/media/skills/guild/undaunted/necrotic-orb/energy-orb.png" alt="energy-orb"></Skill>
                        <Skill name="CombatPrayer" src="https://mainbuilds.com/media/skills/weapon/restoration-staff/blessing-of-protection/combat-prayer.png" alt="combat-prayer"></Skill>
                        <div className="Space-div"></div>
                        <Skill name="LifeGiver" src="https://mainbuilds.com/media/skills/weapon/restoration-staff/panacea/life-giver.png" alt="life-giver"></Skill>
                    </div>
                </fieldset>
            </div >
            <div className="BB-div">
                <fieldset className="BB-fieldset">
                    <legend className="BB-legend">
                        <h1 className="Bar-h1">Backbar</h1>
                    </legend>
                    <div className="Skills-div">
                        <Skill name="DeceptivePredator" src="https://mainbuilds.com/media/skills/class/warden/animal-companions/falcons-swiftness/deceptive-predator.png" alt="deceptive-predator"></Skill>
                        <Skill name="ShimmeringShield" src="https://mainbuilds.com/media/skills/class/warden/winters-embrace/crystallized-shield/shimmering-shield.png" alt="shimmering-shield"></Skill>
                        <Skill name="IceFortress" src="https://mainbuilds.com/media/skills/class/warden/winters-embrace/frost-cloak/ice-fortress.png" alt="ice-fortress"></Skill>
                        <Skill name="BlueBetty" src="https://mainbuilds.com/media/skills/class/warden/animal-companions/betty-netch/blue-betty.png" alt="blue-betty"></Skill>
                        <Skill name="OverflowingAltar" src="https://mainbuilds.com/media/skills/guild/undaunted/blood-altar/overflowing-altar.png" alt="overflowing-altar"></Skill>
                        <div className="Space-div"></div>
                        <Skill name="HealingThicket" src="https://mainbuilds.com/media/skills/class/warden/green-balance/secluded-grove/healing-thicket.png" alt="healing-thicket"></Skill>
                    </div>
                </fieldset>
            </div>
            <div className="Slotted-div">
                <table className="Slotted-table">
                    <thead>
                        <tr>
                            <th className="Slotted-th">Button</th>
                            <th className="Slotted-th">Frontbar</th>
                            <th className="Slotted-th">Backbar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="X-h1"><span className="X-span">X</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/weapon/restoration-staff/radiating-regeneration" target="_blank" rel="noopener noreferrer">Radiating Regeneration</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/warden/animal-companions/deceptive-predator" target="_blank" rel="noopener noreferrer">Deceptive Predator</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="Y-h1"><span className="Y-span">Y</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/alliance-war/support/lingering-flare" target="_blank" rel="noopener noreferrer">Lingering Flare</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/warden/winters-embrace/shimmering-shield" target="_blank" rel="noopener noreferrer">Shimmering Shield</a></td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="B-h1"><span className="B-span">B</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/warden/winters-embrace/polar-wind" target="_blank" rel="noopener noreferrer">Polar Wind</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/warden/winters-embrace/ice-fortress" target="_blank" rel="noopener noreferrer">Ice Fortress</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="LB-h1"><span className="LB-span">LB</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/guild/undaunted/energy-orb" target="_blank" rel="noopener noreferrer">Energy Orb</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/warden/animal-companions/blue-betty" target="_blank" rel="noopener noreferrer">Blue Betty</a></td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td"><h1 className="RB-h1"><span className="RB-span">RB</span></h1></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/weapon/restoration-staff/combat-prayer" target="_blank" rel="noopener noreferrer">Combat Prayer</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/skills/guild/undaunted/overflowing-altar" target="_blank" rel="noopener noreferrer">Overflowing Altar</a></td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Transparent-td"><h1 className="LB-h1"><span className="LB-span">LB</span><span className="Space-span">RB</span></h1></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/weapon/restoration-staff/life-giver" target="_blank" rel="noopener noreferrer">Life Giver</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/skills/warden/green-balance/healing-thicket" target="_blank" rel="noopener noreferrer">Healing Thicket</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="Gear-div">
                <table className="Gear-table">
                    <thead>
                        <tr>
                            <th className="Slotted-th">Slot</th>
                            <th className="Slotted-th">Set</th>
                            <th className="Slotted-th">Weapon</th>
                            <th className="Slotted-th">Weight</th>
                            <th className="Slotted-th">Trait</th>
                            <th className="Slotted-th">Enchantment</th>
                            <th className="Slotted-th">Poison</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="White-td">Frontbar One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/healers-habit" target="_blank" rel="noopener noreferrer">Healer's Habit</a></td>
                            <td className="White-td">Restoration Staff</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/weapon/powered" target="_blank" rel="noopener noreferrer">Powered</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-absorb-magicka" target="_blank" rel="noopener noreferrer">Glyph of Absorb Magicka</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Frontbar Two</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Backbar One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/healers-habit" target="_blank" rel="noopener noreferrer">Healer's Habit</a></td>
                            <td className="White-td">Restoration Staff</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/weapon/powered" target="_blank" rel="noopener noreferrer">Powered</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-absorb-magicka" target="_blank" rel="noopener noreferrer">Glyph of Absorb Magicka</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Backbar Two</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Head</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/gaze-of-sithis" target="_blank" rel="noopener noreferrer">Gaze of Sithis</a></td>
                            <td className="BW-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka" target="_blank" rel="noopener noreferrer">Glyph of Magicka</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Shoulder</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/sentinel-of-rkugamz" target="_blank" rel="noopener noreferrer">Sentinel of Rkugamz</a></td>
                            <td className="BG-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-health" target="_blank" rel="noopener noreferrer">Glyph of Health</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Chest</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/alessian-order" target="_blank" rel="noopener noreferrer">Alessian Order</a></td>
                            <td className="BW-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka" target="_blank" rel="noopener noreferrer">Glyph of Magicka</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Hands</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/alessian-order" target="_blank" rel="noopener noreferrer">Alessian Order</a></td>
                            <td className="BG-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-health" target="_blank" rel="noopener noreferrer">Glyph of Health</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Belt</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/alessian-order" target="_blank" rel="noopener noreferrer">Alessian Order</a></td>
                            <td className="BW-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-health" target="_blank" rel="noopener noreferrer">Glyph of Health</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Pants</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/alessian-order" target="_blank" rel="noopener noreferrer">Alessian Order</a></td>
                            <td className="BG-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka" target="_blank" rel="noopener noreferrer">Glyph of Magicka</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Shoes</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/alessian-order" target="_blank" rel="noopener noreferrer">Alessian Order</a></td>
                            <td className="BW-td">-</td>
                            <td className="Heavy-td">Heavy</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/armor/reinforced" target="_blank" rel="noopener noreferrer">Reinforced</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-health" target="_blank" rel="noopener noreferrer">Glyph of Health</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Necklace</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/healers-habit" target="_blank" rel="noopener noreferrer">Healer's Habit</a></td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/jewelry/arcane" target="_blank" rel="noopener noreferrer">Arcane</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka-recovery" target="_blank" rel="noopener noreferrer">Glyph of Magicka Recovery</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                        <tr className="White-tr">
                            <td className="White-td">Ring One</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/sets/healers-habit" target="_blank" rel="noopener noreferrer">Healer's Habit</a></td>
                            <td className="BW-td">-</td>
                            <td className="BW-td">-</td>
                            <td className="White-td"><a href="https://eso-hub.com/en/traits/jewelry/arcane" target="_blank" rel="noopener noreferrer">Arcane</a></td>
                            <td className="White-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka-recovery" target="_blank" rel="noopener noreferrer">Glyph of Magicka Recovery</a></td>
                            <td className="BW-td">-</td>
                        </tr>
                        <tr className="Gray-tr">
                            <td className="Gray-td">Ring Two</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/sets/healers-habit" target="_blank" rel="noopener noreferrer">Healer's Habit</a></td>
                            <td className="BG-td">-</td>
                            <td className="BG-td">-</td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/traits/jewelry/arcane" target="_blank" rel="noopener noreferrer">Arcane</a></td>
                            <td className="Gray-td"><a href="https://eso-hub.com/en/enchanting-runes-and-glyphs/glyph/glyph-of-magicka-recovery" target="_blank" rel="noopener noreferrer">Glyph of Magicka Recovery</a></td>
                            <td className="BG-td">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="North-div">
                <div className="NW-div">
                    <fieldset className="Consumable-fieldset">
                        <legend className="Consumable-legend">
                            <h1 className="Consumable-h1">Consumable</h1>
                        </legend>
                        <div className="Consumable-div">
                            <img class="Consumable-img" src="/media/consumable/bewitched-sugar-skulls-food.png" alt="bewitched-sugar-skills-food"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/food-drinks/delicacies/bewitched-sugar-skulls" target="_blank" rel="noopener noreferrer">Bewitched Sugar Skulls</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Increase maximum <span className="Health-span">Health</span> by 4620, maximum <span className="Stamina-span">Stamina</span> and <span className="Magicka-span">Magicka</span> by 4250, and <span className="HR-span">Health Recovery</span> by 462 for 2 hours.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="NE-div">
                    <fieldset className="Consumable-fieldset">
                        <legend className="Mundus-legend">
                            <h1 className="Mundus-h1">Mundus</h1>
                        </legend>
                        <div className="Mundus-div">
                            <img class="Mundus-img" src="/media/mundus/the-ritual-mundus.png" alt="the-ritual-mundus"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/mundus-stones/the-ritual" target="_blank" rel="noopener noreferrer">The Ritual</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Increases healing effectiveness by 8%.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="South-div">
                <div className="SW-div">
                    <fieldset className="Potion-fieldset">
                        <legend className="Potion-legend">
                            <h1 className="Potion-h1">Potion</h1>
                        </legend>
                        <div className="Potion-div">
                            <img class="Potion-img" src="/media/potion/alliance-spell-draught.png" alt="alliance-spell-draught"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://en.uesp.net/wiki/Online:Potions" target="_blank" rel="noopener noreferrer">Alliance Spell Draught</a></h1>
                                </span>
                                <div className="Description-div">
                                    <span className="Description-span">Restore 7582 <span className="Magicka-span">Magicka</span> immediately. Grant <span className="Gold-span">Major Intellect</span>, <span className="Gold-span">Major Sorcery</span>, and <span className="Gold-span">Major Prophecy</span>  for 47.3 seconds.</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="SE-div">
                    <fieldset className="Race-fieldset">
                        <legend className="Race-legend">
                            <h1 className="Race-h1">Race</h1>
                        </legend>
                        <div className="Race-div">
                            <img class="Race-img" src="/media/race/orc-race.png" alt="orc"></img>
                            <div class="Name-div">
                                <span className="Name-span">
                                    <h1 className="Name-h1"><a href="https://eso-hub.com/en/skills/racial/orc-skills" target="_blank" rel="noopener noreferrer">Orc</a></h1>
                                </span>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="Affliction-div">
                <h1 className="Affliction-h1">Affliction</h1>
                <table className="Affliction-table">
                    <thead>
                        <th className="Vampire-th">Vampire</th>
                        <th className="None-th">None</th>
                        <th className="Werewolf-th">Werewolf</th>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="Vampire-td">No</td>
                            <td className="None-td">Yes</td>
                            <td className="Werewolf-td">No</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="Attributes-div">
                <h1 className="Attributes-h1">Attribute Points</h1>
                <table className="Attribute-table">
                    <thead>
                        <th className="Magicka-th">Magicka</th>
                        <th className="Health-th">Health</th>
                        <th className="Stamina-th">Stamina</th>
                    </thead>
                    <tbody>
                        <tr className="White-tr">
                            <td className="Magicka-td">34</td>
                            <td className="Health-td">30</td>
                            <td className="Stamina-td">0</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="Champion-div">
                <h1 className="Champion-h1">Slotted Champion Points</h1>
                <div className="Warfare-div">
                    <h1 className="Warfare-h1">Warfare</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/arcane-supremacy" target="_blank" rel="noopener noreferrer">Arcane Supremacy</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/rejuvenator" target="_blank" rel="noopener noreferrer">Rejuvenator</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/soothing-tide" target="_blank" rel="noopener noreferrer">Soothing Tide</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/untamed-aggression" target="_blank" rel="noopener noreferrer">Untamed Aggression</a></span></li>
                    </ul>
                </div>
                <div className="Fitness-div">
                    <h1 className="Fitness-h1">Fitness</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/boundless-vitality" target="_blank" rel="noopener noreferrer">Boundless Vitality</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/fortified" target="_blank" rel="noopener noreferrer">Fortified</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/rejuvenation" target="_blank" rel="noopener noreferrer">Rejuvenation</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/strategic-reserve" target="_blank" rel="noopener noreferrer">Strategic Reserve</a></span></li>
                    </ul>
                </div>
                <div className="Craft-div">
                    <h1 className="Craft-h1">Craft</h1>
                    <ul className="Champion-ul">
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/liquid-efficiency" target="_blank" rel="noopener noreferrer">Liquid Efficiency</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/rationer" target="_blank" rel="noopener noreferrer">Rationer</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/steeds-blessing" target="_blank" rel="noopener noreferrer">Steed's Blessing</a></span></li>
                        <li className="Champion-li"><span className="Champion-span"><a href="https://eso-hub.com/en/champion-points/star/treasure-hunter" target="_blank" rel="noopener noreferrer">Treasure Hunter</a></span></li>
                    </ul>
                </div>
                <div className="GIF-div">
                    <h1 className="GIF-h1">Build in action!</h1>
                    <img className="GIF-img" src="/media/gif/magic-warden-healer-mother.gif" alt="magic-warden-healer-mother-gif"></img>
                </div>
            </div>
        </div >
    );
}

export default Modal;