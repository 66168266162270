import Bomber from "./pages/builds/pvp/magic-necromancer/bomber";
import Corrosive from "./pages/builds/pvp/magic-dragonknight/corrosive";
import Disclaimer from "./pages/disclaimer/disclaimer";
import Discord from "./pages/discord/discord";
import Executioner from "./pages/builds/pvp/magic-sorcerer/executioner";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Healer from "./pages/builds/pvp/magic-warden/heal-mother";
import Home from "./pages/home/home";
import Submit from "./pages/submit/submit";
import Tales from "./pages/tales/tales";
import Timer from "./pages/timer/timer";
import { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

class App extends Component {
    render() {
        return (
            <div className="App-div">
                <Header />
                <Routes>
                    <Route exact path="" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/:wildcard" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/:wildcard/:wildcard" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/:wildcard/:wildcard/:wildcard" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/:wildcard/:wildcard/:wildcard/:wildcard" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/:wildcard/:wildcard/:wildcard/:wildcard/:wildcard" element={<Navigate to="/home" state={{ todos: [] }} replace={true} />} />
                    <Route exact path="/disclaimer" element={< Disclaimer />} />
                    <Route exact path="/discord" element={< Discord />} />
                    <Route exact path="/home" element={< Home />} />
                    <Route exact path="/pvp-magic-necromancer-bomber" element={< Bomber />} />
                    <Route exact path="/pvp-magic-dragonknight-corrosive" element={< Corrosive />} />
                    <Route exact path="/pvp-magic-sorcerer-executioner" element={< Executioner />} />
                    <Route exact path="/pvp-magic-warden-heal-mother" element={< Healer />} />
                    <Route exact path="/submit" element={< Submit />} />
                    <Route exact path="/tales" element={< Tales />} />
                    <Route exact path="/timer" element={< Timer />} />
                </Routes>
                < Footer />
            </div>
        )
    }
}

export default App;