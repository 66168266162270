import React from "react";

class Submit extends React.Component {
    render() {
        return (
            <div className="Content-div">
                <fieldset className="Submit-fieldset">
                    <legend className="Submit-legend">
                        <h1 className="Submit-h1">Build Submission</h1>
                    </legend>
                    <div className="Submit-div">
                        <p className="Submit-p">
                            <span className="Number-span">1.)</span> Download build <a className="Submit-a" href="https://mainbuilds.com/media/excel/mb-build-form.xlsx" download="" rel="noopener noreferrer" target="_blank">form</a>.
                        </p>
                        <p className="Submit-p">
                            <span className="Number-span">2.)</span> Email completed build form to <a className="Submit-a" href="mailto:mainbuilds@outlook.com">MainBuilds</a>.
                        </p>
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default Submit;