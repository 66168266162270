import React from "react";
import $ from 'jquery';

$(document).ready(function () {
    $("#Credit-div").hide();
});

function displayCredit() {
    var div = document.getElementById("Credit-div");
    div.style.display = div.style.display === 'block' ? 'none' : 'block';
}

/* Halo timers */
var Halo1Minute = new Audio("https://mainbuilds.com/media/audio/halo-timer/halo-3-1-minute.wav");
var Halo30Seconds = new Audio("https://mainbuilds.com/media/audio/halo-timer/halo-3-30-seconds.wav");
var Halo10Seconds = new Audio("https://mainbuilds.com/media/audio/halo-timer/halo-3-10-seconds.wav");

$(document).ready(function () {
    /* Hides Down buttons */
    $("#LlothisReset-button").hide();
    $("#FelmsReset-button").hide();
    function Bosstimer(
        boss,
        resetCount,
        untilBack,
        counter,
        percentageCount,
        barWidth,
        audio
    ) {
        this.boss = boss;
        this.resetCount = resetCount;
        this.count = resetCount;
        this.untilBack = untilBack;
        this.downTime = untilBack;
        this.counter = counter;
        this.percentageCount = percentageCount;
        this.barWidth = barWidth;
        this.audio = audio;
        this.Halo1Minute = Halo1Minute;
        this.Halo30Seconds = Halo30Seconds;
        this.Halo10Seconds = Halo10Seconds;
        var thisBosstimer = this;
        this.startTimer = function () {
            this.count = resetCount - untilBack;
            percentageCount = 1;
            clearInterval(thisBosstimer.counter);
            counter = setInterval(this.timer, 1000);/* 1000 means that this will run everyone second. */
            $("#" + boss + "Start-button").hide();
            $("#" + boss + "Reset-button").show();
            thisBosstimer.count = this.count;
            thisBosstimer.percentageCount = this.percentageCount;
            thisBosstimer.counter = this.counter;
        };
        this.bossDown = function () {
            thisBosstimer.count = resetCount;
            percentageCount = 0;
            barWidth = 0;
            thisBosstimer.downTime = untilBack;
            $("#" + boss + "PercentBar-div").css("width", 0 + "%");
            clearInterval(counter);
            counter = setInterval(this.timer, 1000);/* 1000 means that this will run everyone second. */
            $("#" + boss + "Reset-button").hide();
            if ($("#" + boss + "-table").hasClass("warning")) {
                $("#" + boss + "-table").removeClass("warning");
            }
            thisBosstimer.percentageCount = this.percentageCount;
            thisBosstimer.barWidth = this.barWidth;
            thisBosstimer.downTime = this.downTime;
            thisBosstimer.counter = this.counter;
        };
        this.timer = function () {
            var timerText = 0;
            var warningTime = 4;
            var barText = "";
            var count = thisBosstimer.count;
            var downTime = thisBosstimer.downTime;

            count = count - 1;
            barText = Math.round((count * 100) / (resetCount - untilBack)) + "%";
            /* Boss down. */
            if (count > resetCount - untilBack) {
                downTime = downTime - 1;
                $("#" + boss + "Text-div").text("Spawning in:");
                if (downTime < warningTime) {
                    $("#" + boss + "-table").addClass("warning");
                    audio.play();
                }
                timerText = downTime;
            } /* Boss up. */
            else {
                barWidth = (percentageCount * 100) / (resetCount - untilBack);
                percentageCount = percentageCount + 1; //ok

                if (downTime < warningTime && $("#" + boss + "-table").hasClass("warning")) {
                    $("#" + boss + "-table").removeClass("warning");
                }
                $("#" + boss + "PercentBar-div").css("width", barWidth + "%");
                $("#" + boss + "PercentBar-div").text(barText);
                timerText = count;
                $("#" + boss + "Text-div").text("Enrages in:");
                $("#" + boss + "Reset-button").show();
            }
            if (count === 60) {
                Halo1Minute.play();
            }
            if (count === 30) {
                Halo30Seconds.play();
            }
            if (count === 10) {
                Halo10Seconds.play();
            }
            if (count === 3) {
                audio.play();
            }
            var m = parseInt(timerText / 60.0);
            var sec = timerText % 60;
            if (sec < 10) {
                sec = "0" + sec;
            }
            var t = m + ":" + sec;
            $("#" + boss + "Timer-div").text(t);
            if (count <= 0) {
                clearInterval(counter);
                return;
            }
            thisBosstimer.count = count;
            thisBosstimer.downTime = downTime;
            thisBosstimer.percentageCount = percentageCount;
        };
    }
    /* Initialize Felms object. */
    var FelmsAudio = new Audio("https://mainbuilds.com/media/audio/countdown-alarm/3-seconds-countdown.wav");
    var Felms = new Bosstimer(
        "Felms",
        180 + 45,
        45,
        undefined,
        0,
        0,
        FelmsAudio,
        Halo1Minute,
        Halo30Seconds,
        Halo10Seconds
    );
    /* Click on Felms startButton. */
    $("#FelmsStart-button").click(function () {
        Felms.startTimer();
    });
    /* Click on downButton Felms. */
    $("#FelmsReset-button").click(function () {
        Felms.bossDown();
    });
    /* Initialize Llothis object. */
    var LlothisAudio = new Audio("https://mainbuilds.com/media/audio/countdown-alarm/3-seconds-countdown.wav");
    var Llothis = new Bosstimer(
        "Llothis",
        180 + 45,
        45,
        undefined,
        0,
        0,
        LlothisAudio,
        Halo1Minute,
        Halo30Seconds,
        Halo10Seconds
    );
    /* Click on Llothis startButton. */
    $("#LlothisStart-button").click(function () {
        Llothis.startTimer();
    });
    /* Click on downButton Llothis. */
    $("#LlothisReset-button").click(function () {
        Llothis.bossDown();
    });
});


class Timer extends React.Component {
    render() {
        return (
            <div className="Content-div">
                <fieldset className="Llothis-fieldset">
                    <legend className="Llothis-legend">
                        <h1 className="Llothis-h1">Llothis</h1>
                    </legend>
                    <table id="Llothis-table">
                        <tbody className="Llothis-tbody">
                            <tr className="LlothisProgress-tr">
                                <td className="Timer-td">
                                    <div className="LlothisText-div"></div>
                                    <div id="LlothisPercentBar-div"></div>
                                </td>
                            </tr>
                            <tr className="Content-tr">
                                <div id="LlothisText-div"></div>
                                <div id="LlothisTimer-div"></div>
                                <td className="Buttons-td">
                                    <button id="LlothisStart-button">Start</button>{/* <!-- Startbutton --> */}
                                    <button id="LlothisReset-button">Down</button>{/* <!-- Resetbutton --> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset className="Felms-fieldset">
                    <legend className="Felms-legend">
                        <h1 className="Felms-h1">Felms</h1>
                    </legend>
                    <table id="Felms-table">
                        <tbody className="Felms-tbody">
                            <tr className="FelmsProgress-tr">
                                <td className="Timer-td">
                                    <div className="FelmsText-div"></div>
                                    <div id="FelmsPercentBar-div"></div>
                                </td>
                            </tr>
                            <tr className="Content-tr">
                                <div id="FelmsText-div"></div>
                                <div id="FelmsTimer-div"></div>
                                <td className="Buttons-td">
                                    <button id="FelmsStart-button">Start</button>{/* <!-- Startbutton --> */}
                                    <button id="FelmsReset-button">Down</button>{/* <!-- Resetbutton --> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset className="Instructions-fieldset">
                    <legend className="Instructions-legend">
                        <h1 className="Instructions-h1">Instructions</h1>
                    </legend>
                    <div className="Instructions-div">
                        <p className="Instructions-p">
                            <span className="Instructions-span">1.) Confirm your volume is not off if you would like to receive the audio prompts.</span>
                        </p>
                        <p className="Instructions-p">
                            <span className="Instructions-span">2.) Press the respective Start button(s) for Llothis and/or Felms when they first spawn.</span>
                        </p>
                        <p className="Instructions-p">
                            <span className="Instructions-span">3.) Press the respective Down button(s) for Llothis and/or Felms when they are killed.</span>
                        </p>
                        <button className="Credit-button" onClick={displayCredit}>Credit</button>
                        <div id="Credit-div">
                            <p className="Credit-p">
                                <span className="Credit-span">Thank you to <a className="Instructions-a" href="https://codepen.io/gmume/details/RwagvgQ" target="_blank" rel="noopener noreferrer">Melanie Gmünder</a> for the JavaScript used in this timer.</span>
                            </p>
                        </div>
                    </div>
                </fieldset >
            </div >
        )
    }
}

export default Timer;