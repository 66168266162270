/*Start Dragonknight:*/
export const NoxiousBreath = {
    "name": "Noxious Breath",
    "effect_1": "Exhale a corrosive blast to enemies in front of you, dealing 1799 Poison Damage and an additional 2980 Poison Damage over 20 seconds. Afflicts enemies with <span class='Gold-span'>Major Breach</span>, reducing their Physical and Spell Resistance by 5948.",
    "effect_2": "Converts into a Stamina ability and deals Poison Damage. Applies <span class='Gold-span'>Major Breach</span> to enemies, reducing their Resistances.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/ardent-flame/fiery-breath/noxious-breath.png",
}
export const DeepBreath = {
    "name": "Deep Breath",
    "effect_1": "Channel draconic energy to suck in the air around you, dealing 870 Flame Damage to nearby enemies and healing you for 100% of the damage caused. Any enemy hit that is casting is interrupted, set Off Balance, and stunned for 2 seconds. After 2.5 seconds, you exhale fire, dealing 2249 Flame Damage to nearby enemies.",
    "effect_2": "Initial hit interrupts enemies that are casting. Final explosion damage increased.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/draconic-power/inhale/deep-breath.png",
}
export const FlamesOfOblivion = {
    "name": "Flames of Oblivion",
    "effect_1": "Activate an aura of flames which launches a fireball at 3 enemies every 5 seconds, dealing 1799 Flame Damage. While slotted, you gain <span class='Gold-span'>Major Prophecy and Savagery</span>, increasing your Spell and Weapon Critical rating by 2629.",
    "effect_2": "Now launches three fireballs instead of one.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/ardent-flame/inferno/flames-of-oblivion.png",
}
export const ShatteringRocks = {
    "name": "Shattering Rocks",
    "effect_1": "Encase an enemy in molten rock, stunning them for 2.5 seconds. When the stun ends, they take 1199 Flame Damage and you are healed for 2323 Health. This stun cannot be blocked or dodged.",
    "effect_2": "When the stun ends, you are also healed.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/earthern-heart/petrify/shattering-rocks.png",
}
export const MoltenWhip = {
    "name": "Molten Whip",
    "effect_1": "Lash an enemy with flame, dealing 2323 Flame Damage. If you strike an enemy that is immobile or stunned, you set them Off Balance. Whenever you activate a different Ardent Flame ability, you gain a stack of Seething Fury, increasing the damage of your next Molten Whip by 20% and your Weapon and Spell Damage by 100 for 15 seconds. This effect stacks up to 3 times.",
    "effect_2": "Splits the cost between Magicka and Stamina. While slotted, your other Ardent Flame abilities increase the damage of your next Molten Whip cast and Weapon and Spell Damage.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/ardent-flame/lava-whip/molten-whip.png",
}
export const CoagulatingBlood = {
    "name": "Coagulating Blood",
    "effect_1": "Draw on your draconic blood to heal for 2999, increasing by up to 50% additional healing based on your missing Health. You also gain <span class='Gold-span'>Major Fortitude</span>, increasing your Health Recovery by 30% for 20 seconds.",
    "effect_2": "Now heals based on your offensive stats rather than Health and increases the amount of healing based on your missing Health.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/draconic-power/dragon-blood/coagulating-blood.png",
}
export const MoltenArmaments = {
    "name": "Molten Armaments",
    "effect_1": "Charge you and your grouped allies' weapons with volcanic power to gain <span class='Gold-span'>Major Brutality and Sorcery</span>, increasing your Weapon and Spell Damage by 20% for 30 seconds. You also gain Empower for the duration, increasing the damage of your Heavy Attacks against monsters by 70%.",
    "effect_2": "You also gain <span class='Gold-span'>Empower</span>, and reduces the cost as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/earthern-heart/molten-weapons/molten-armaments.png",
}
export const CorrosiveArmor = {
    "name": "Corrosive Armor",
    "effect_1": "Oxidize the green Dragon blood in your veins, limiting incoming damage to 3% of your Max Health and dealing 347 Poison Damage to nearby enemies each second for 10 seconds. While active, this ability and your direct damage dealt ignores enemy Physical and Spell Resistance.",
    "effect_2": "Deals Poison Damage. While active, your direct attacks ignore Physical and Spell Resistance, but you cannot generate Ultimate.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/earthern-heart/magma-armor/corrosive-armor.png",
}
export const BurningTalons = {
    "name": "Burning Talons",
    "effect_1": "Call forth talons from the ground, dealing 1799 Flame Damage to enemies near you, an additional 1635 Flame Damage over 5 seconds, and immobilizing them for 4 seconds. An ally near the talons can activate the Ignite synergy, dealing 2812 Flame Damage to all enemies held within them.",
    "effect_2": "Deals additional damage over time.",
    "icon": "https://mainbuilds.com/media/skills/class/dragonknight/draconic-power/dark-talons/burning-talons.png",
}
/*End Dragonknight:*/

/*Start Necromancer:*/
export const StalkingBlastbones = {
    "name": "Stalking Blastbones",
    "effect_1": "Summon a flaming skeleton from the ground after 2.5 seconds. The skeleton runs after the target and explodes when it gets close to them, dealing 3600 Flame Damage to all enemies nearby. Every second the skeleton spends chasing its target increases the damage of the explosion by 10%, up to a maximum of 50% more damage. Creates a corpse on death.",
    "effect_2": "The skeleton deals more damage the longer it chases the target.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/grave-lord/blastbones/stalking-blastbones.png",
}
export const BlightedBlastbones = {
    "name": "Blighted Blastbones",
    "effect_1": "Summon a decaying skeleton from the ground after 2.5 seconds. The skeleton runs after the target and explodes when it gets close to them, dealing 3600 Disease Damage to all enemies nearby and applying the Diseased status effect and Major Defile to them for 4 seconds, reducing their healing received and damage shield strength by 12%. Creates a corpse on death.",
    "effect_2": "Converts into a Stamina ability that deals Disease Damage to enemies instead of empowering you. Applies Major Defile and the Diseased status effect to enemies hit.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/grave-lord/blastbones/blighted-blastbones.png",
}
export const AvidBoneyard = {
    "name": "Avid Boneyard",
    "effect_1": "Desecrate the ground at the target location, dealing 3580 Frost Damage over 10 seconds. Consumes a corpse on cast to deal 50% more damage. You or an ally standing in the graveyard can activate the Grave Robber synergy, dealing 3375 Frost Damage to enemies in the area and healing for the damage done.",
    "effect_2": "You can use your own synergy.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/grave-lord/boneyard/avid-boneyard.png",
}
export const RuinousScythe = {
    "name": "Ruinous Scythe",
    "effect_1": "Slice into your enemy's life force, dealing 1799 Bleed Damage, applying the Hemorrhaging status effect, and setting them Off Balance for 7 seconds. You heal for 2400 Health for the first enemy hit, and an additional 800 for each additional enemy, up to five times. The healing of this ability scales off your Max Health.",
    "effect_2": "Converts to a Stamina ability and deals Bleed Damage, applying the Hemorrhaging status effect. Enemies hit are set Off Balance.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/death-scythe/ruinous-scythe.png",
}
export const HungryScythe = {
    "name": "Hungry Scythe",
    "effect_1": "Slice into your enemy's life force, dealing 1742 Magic Damage. You heal for 2400 Health for the first enemy hit, and an additional 800 for each additional enemy, up to five times. After dealing damage, you heal for 991 Health every 2 seconds over 10 seconds. The healing of this ability scales off your Max Health.",
    "effect_2": "Gain a heal over time and reduces the cost as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/death-scythe/hungry-scythe.png",
}
export const PestilentCollossus = {
    "name": "Pestilent Collossus",
    "effect_1": "Unleash a pestilent Flesh Colossus to pulverize enemies in the area. The Colossus smashes the ground three times over 3 seconds, dealing 2880 3023, and 3175 Disease Damage with the first, second, and third smash. Dealing damage applies <span class='Gold-span'>Major Vulnerability</span> to any enemy hit for 12 seconds, increasing their damage taken by 10%.",
    "effect_2": "Deals Disease Damage. The Colossus deals more damage with each smash.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/grave-lord/frozen-colossus/pestilent-colossus.png",
}
export const ResistantFlesh = {
    "name": "Resistant Flesh",
    "effect_1": "Sacrifice your own power to repair damaged flesh, healing you or an ally in front of you for 3600 Health but applying <span class='Gold-span'>Minor Defile</span> to yourself for 4 seconds, reducing your healing received and Health Recovery by 8%. You grant the target Spell and Physical Resistance equal to half the amount healed for 3 seconds.",
    "effect_2": "Grant resistances to the target equal to half the amount healed.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/living-death/render-flesh/resistant-flesh.png",
}
export const NecroticPotency = {
    "name": "Necrotic Potency",
    "effect_1": "Sap the lingering life from fresh corpses, granting you 6 Ultimate and healing 682 Health every 1 second for 2 seconds per additional corpse. This ability scales off your Max Health. While slotted, your damage taken is reduced by 3%.",
    "effect_2": "Gain more Ultimate per corpse.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/bitter-harvest/necrotic-potency.png",
}
export const SummonersArmor = {
    "name": "Summoner's Armor",
    "effect_1": "Wrap yourself in hardened bone, granting you <span class='Gold-span'>Major Resolve</span>, increasing your Physical Resistance and Spell Resistance by 5948 for 20 seconds. While active, reduce the cost of Blastbones, Skeletal Mage, and Spirit Mender by 15%. Creates a corpse when the effect completes.",
    "effect_2": "Reduces the cost of your Necromancer summon abilities.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/bone-tyrant/bone-armor/summoners-armor.png",
}
export const SpiritGuardian = {
    "name": "Spirit Guardian",
    "effect_1": "Conjure a ghostly spirit to do your bidding and stay by your side for 16 seconds. The spirit heals you or the lowest Health ally around you every 2 seconds, restoring 1370 Health. While active 10% of the damage you take is transferred to the spirit instead. Creates a corpse on death.",
    "effect_2": "Some of the damage you take is transferred to the spirit instead.",
    "icon": "https://mainbuilds.com/media/skills/class/necromancer/living-death/spirit-mender/spirit-guardian.png",
}
/*End Necromancer:*/

/*Start Sorcerer:*/
export const EndlessFury = {
    "name": "Endless Fury",
    "effect_1": "Call down lightning to strike an enemy, dealing 871 Shock Damage. If the enemy falls to or below 20% Health within 4 seconds of being struck, an explosion deals an additional 3195 Shock Damage to them and 696 Shock Damage to other enemies nearby. If any enemy is killed within 5 seconds of being hit with this ability, you restore 4860 Magicka.",
    "effect_2": "Reduces the cost and restores Magicka if the target dies after this ability dealt damage to them.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/storm-calling/mages-fury/endless-fury.png"
}
export const SummonTwilightMatriarch = {
    "name": "Summon Twilight Matriarch",
    "effect_1": "Call on Azura to send a twilight matriarch to fight at your side. The twilight matriarch's zap deals 347 Shock Damage and its kick deals 347 Shock Damage. Once summoned, you can activate the twilight matriarch's special ability for 4590 Magicka, causing it to heal 2 friendly targets for 3600 and itself for 1799. The twilight matriarch remains until killed or unsummoned.",
    "effect_2": "You summon a twilight matriarch. Once summoned, you can activate the twilight's special ability to heal the matriarch and up to 2 other friendly targets.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/daedric-summoning/summon-winged-twilight/summon-twilight-matriarch.png"
}
export const CrystalFragments = {
    "name": "Crystal Fragments",
    "effect_1": "Conjure dark crystals to bombard an enemy, dealing 2483 Magic Damage. Your next non-Ultimate ability cast within 3 seconds costs 10% less. While slotted casting a Health, Magicka, or Stamina ability has a 33% chance of causing your next Crystal Fragments to be instant, deal 66% more damage, and cost 50% less Magicka.",
    "effect_2": "Casting abilities has a chance to make your next Crystal Fragments instant, deal more damage, and cost less.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/dark-magic/crystal-shard/crystal-fragments.png"
}
export const SummonChargedAtronach = {
    "name": "Summon Charged Atronach",
    "effect_1": "Summon an immobile storm atronach at the target location. Its arrival deals 2323 Shock Damage and stuns enemies for 3 seconds. The atronach zaps the closest enemy, dealing 1124 Shock Damage every 1 second, and periodically deals 2323 Shock Damage to enemies around it. An ally near the atronach can activate the Charged Lightning synergy, granting the ally and the atronach <span class='Gold-span'>Major Berserk</span>, increasing their damage done by 10% for 8 seconds.",
    "effect_2": "The atronach gains a powerful area of effect attack, which always applies Concussion.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/daedric-summoning/summon-storm-atronach/summon-charged-atronach.png",
}
export const Streak = {
    "name": "Streak",
    "effect_1": "Transform yourself into pure energy and flash forward, dealing 1438 Shock Damage to enemies in your wake and stunning them for 3 seconds. This effect cannot be blocked. Casting again within 4 seconds costs 33% more Magicka.",
    "effect_2": "Now deals damage and stuns enemies between your beginning and final location.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/storm-calling/bolt-escape/streak.png",
}
export const DarkConversion = {
    "name": "Dark Conversion",
    "effect_1": "Bargain with darkness to restore 8090 Health and 3600 Magicka instantly, and an additional 2400 Magicka over 20 seconds.",
    "effect_2": "Has reduced cost.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/dark-magic/dark-exchange/dark-conversion.png",
}
export const HardenedWard = {
    "name": "Hardened Ward",
    "effect_1": "Conjure globes of Daedric energy for protection, granting a damage shield for you and your pets that absorbs 7323 damage for 6 seconds. If no pets are affected, you also heal for 2400 Health. This ability scales off the higher of your Max Health or Magicka and the shield is capped at 72% of your Max Health.",
    "effect_2": "Damage shield absorbs more and has a higher capped value.",
    "icon": "https://mainbuilds.com/media/skills/class/sorcerer/daedric-summoning/conjured-ward/hardened-ward.png",
}
/*End Sorcerer:*/

/*Start Warden:*/
export const DeceptivePredator = {
    "name": "Deceptive Predator",
    "effect_1": "Invoke the spirit of agility to gain <span class='Gold-span'>Major Expedition</span> for 6 seconds, increasing your Movement Speed by 30%. Gain immunity to snares and immobilizations for 4 seconds. While slotted you gain <span class='Gold-span'>Minor Evasion</span>, reducing damage from area attacks by 10%.",
    "effect_2": "Passively grants <span class='Gold-span'>Minor Evasion</span> while slotted.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/animal-companions/falcons-swiftness/deceptive-predator.png"
}
export const PolarWind = {
    "name": "Polar Wind",
    "effect_1": "Envelop yourself and an ally in winter winds, instantly healing for 4958 Health and healing for an additional 1229 Health every 2 seconds over 10 seconds. This ability scales off your Max Health.",
    "effect_2": "Increases the healing over time, and causes the healing to also target a nearby ally.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/winters-embrace/arctic-wind/polar-wind.png"
}
export const ShimmeringShield = {
    "name": "Shimmering Shield",
    "effect_1": "Spin a shield of ice around you, absorbing up to 16527 damage from 3 projectiles. Each time you absorb a projectile you gain 2 Ultimate and gain <span class='Gold-span'>Major Heroism</span> for 6 seconds, granting you 3 Ultimate every 1.5 seconds.",
    "effect_2": "Absorbing projectiles grants you <span class='Gold-span'>Major Heroism</span> and reduces the cost as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/winters-embrace/crystallized-shield/shimmering-shield.png"
}
export const IceFortress = {
    "name": "Ice Fortress",
    "effect_1": "Wrap a thick cloak of ice around you and your allies. The ice grants <span class='Gold-span'>Major Resolve</span>, increasing your Physical and Spell Resistance by 5948 for 30 seconds. You gain Minor Protection, reducing your damage taken by 5% for 30 seconds.",
    "effect_2": "Grants <span class='Gold-span'>Minor Protection</span> to you. Increases the duration as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/winters-embrace/frost-cloak/ice-fortress.png"
}
export const BlueBetty = {
    "name": "Blue Betty",
    "effect_1": "Call a betty netch to your side, which restores 4416 Magicka to you over 25 seconds and grants you <span class='Gold-span'>Major Brutality</span> and <span class='Gold-span'>Major Sorcery</span>, increasing your Weapon and Spell Damage by 20%. Every 5 seconds, the netch removes 1 negative effect from you.",
    "effect_2": "Restores Magicka every second while active.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/animal-companions/betty-netch/blue-betty.png"
}
export const HealingThicket = {
    "name": "Healing Thicket",
    "effect_1": "Swell a healing forest at the target location, instantly healing the most injured friendly target for 2880 Health. The forest continues to heal you and your allies in the area for 958 every 1 second for 6 seconds. The healing over time will continue to heal you or your allies for 4 seconds after leaving the forest.",
    "effect_2": "The healing over time will continue to heal you and your allies after leaving the area.",
    "icon": "https://mainbuilds.com/media/skills/class/warden/green-balance/secluded-grove/healing-thicket.png"
}
/*End Warden:*/

/*Start Destruction Staff:*/
export const CrushingShock = {
    "name": "Crushing Shock",
    "effect_1": "Focus all the elemental energies with your staff and blast an enemy for 696 Flame Damage 696 Frost Damage, and 696 Shock Damage. Enemies hit while casting are interrupted, set Off Balance, and stunned for 3 seconds.",
    "effect_2": "Interrupts spells, putting the caster Off Balance. Reduces cost as ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/weapon/destruction-staff/force-shock/crushing-shock.png"
}
/*End Destruction Staff:*/

/*Start Mages Guild:*/
export const Degeneration = {
    "name": "Degeneration",
    "effect_1": "Bind an enemy with chaotic magic, dealing 3480 Magic Damage over 10 seconds. Excess magic spills out from them, granting you <span class='Gold-span'>Major Brutality</span> and <span class='Gold-span'>Sorcery</span>, increasing your Weapon and Spell Damage by 20% for 20 seconds.",
    "effect_2": "You gain <span class='Gold-span'>Major Brutality</span> and <span class='Gold-span'>Sorcery</span> for a time after casting. Reduces the cost as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/guild/mages-guild/entropy/degeneration.png"
}
export const Balance = {
    "name": "Balance",
    "effect_1": "Barter with Oblivion to trade vitality for power, sacrificing your Health in exchange for 3000 Magicka. After the exchange is complete, you gain <span class='Gold-span'>Major Resolve</span> for 25 seconds, increasing your Physical and Spell Resistance by 5948. The exchange reduces your healing done and damage shield strength by 50% for 4 seconds.",
    "effect_2": "After the exchange is complete, you gain Major Resolve, increasing your Physical and Spell Resistance.",
    "icon": "https://mainbuilds.com/media/skills/guild/mages-guild/equilibrium/balance.png",
}
export const IceComet = {
    "name": "Ice Comet",
    "effect_1": "Call a comet down from the constellations to blast an enemy, dealing 4620 Frost Damage to all enemies in the area, knocking them down, stunning them for 2 seconds, and reducing their Movement Speed by 50% for 5 seconds. After impact, enemies in the target area take 1319 Frost Damage every 1 second for 11 seconds.",
    "effect_2": "Increases the damage, deals Frost Damage, and reduces the Movement Speed of enemies hit.",
    "icon": "https://mainbuilds.com/media/skills/guild/mages-guild/meteor/ice-comet.png",
}
/*End Mages Guild:*/

/*Start Fighters Guild:*/
export const CamouflagedHunter = {
    "name": "Camouflaged Hunter",
    "effect_1": "Invoke your expertise in anatomy and enemy behavior to detect stealthed and invisible enemies around you for 5 seconds. Exposed enemies cannot return to stealth or invisibility for 4 seconds. While slotted you gain <span class='Gold-span'>Major Savagery</span> and <span class='Gold-span'>Prophecy</span>, increasing your Weapon and Spell Critical rating by 2629. You also gain <span class='Gold-span'>Minor Berserk</span> for 5 seconds after dealing Critical Damage from an enemy's flank.",
    "effect_2": "While slotted, dealing Critical Damage from the flank of an enemy grants <span class='Gold-span'>Minor Berserk</span>.",
    "icon": "https://mainbuilds.com/media/skills/guild/fighters-guild/expert-hunter/camouflaged-hunter.png"
}
export const DawnbreakerOfSmiting = {
    "name": "Dawnbreaker of Smiting",
    "effect_1": "Arm yourself with Meridia's sacred sword and dispense her retribution, dealing 3600 Physical Damage to enemies in front of you, an additional 4314 Physical Damage over 6 seconds, and stunning them for 2 seconds.",
    "effect_2": "Increases the damage and stuns enemies hit.",
    "icon": "https://mainbuilds.com/media/skills/guild/fighters-guild/dawnbreaker/dawnbreaker-of-smiting.png",
}
/*End Fighters Guild:*/

/*Start Restoration Staff:*/
export const HealingWard = {
    "name": "Healing Ward",
    "effect_1": "Call on your staff's strength to protect you or the lowest health ally around you with a damage shield that absorbs 2399 damage. The shield's strength is increased by up to 100%, depending on the severity of the target's wounds. While the shield persists, the target is healed for 33% of the shield's remaining strength every second.",
    "effect_2": "Heals target based on the shield's strength every second it persists.",
    "icon": "https://mainbuilds.com/media/skills/weapon/restoration-staff/steadfast-ward/healing-ward.png",
}

export const RadiatingRegeneration = {
    "name": "Radiating Regeneration",
    "effect_1": "Share your staff's life-giving energy, healing you or up to 3 nearby allies for 3594 over 10 seconds.",
    "effect_2": "Heals two additional targets.",
    "icon": "https://mainbuilds.com/media/skills/weapon/restoration-staff/regeneration/radiating-regeneration.png",
}
export const CombatPrayer = {
    "name": "Combat Prayer",
    "effect_1": "Slam your staff down to activate its blessings, healing you and your allies in front of you for 2784 Health. Also grants <span class='Gold-span'>Minor Berserk</span> and <span class='Gold-span'>Minor Resolve</span> increasing you and your allies' damage done by 5% and Physical Resistance and Spell Resistance by 2974 for 10 seconds.",
    "effect_2": "Also grants <span class='Gold-span'>Minor Berserk</span>, increasing you and your allies' damage done. Cost decreases as ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/weapon/restoration-staff/blessing-of-protection/combat-prayer.png",
}
export const LifeGiver = {
    "name": "Life Giver",
    "effect_1": "Release the rejuvenating energies of your staff to swirl around you, healing you or an ally for 2999 Health every 1 second for 5 seconds. When you activate this ability you automatically cast Regeneration, Blessing of Protection, and Steadfast Ward at no cost. These will update based on which morph of each ability you have taken.",
    "effect_2": "Also automatically casts Regeneration, Blessing of Protection, and Steadfast Ward.",
    "icon": "https://mainbuilds.com/media/skills/weapon/restoration-staff/panacea/life-giver.png",
}
/*End Restoration Staff:*/

/*Start Psijic Order:*/
export const TemporalGuard = {
    "name": "Temporal Guard",
    "effect_1": "Step backwards in time, resetting your Health, Magicka, Stamina, and position to what they were 4 seconds ago. While slotted you gain <span class='Gold-span'>Minor Protection</span>, reducing your damage taken by 5%.",
    "effect_2": "While slotted your damage taken is reduced.",
    "icon": "https://mainbuilds.com/media/skills/guild/psijic-order/undo/temporal-guard.png",
}
export const ChanneledAcceleration = {
    "name": "Channeled Acceleration",
    "effect_1": "Bend time and space around you to gain <span class='Gold-span'>Major Expedition</span> for 12 seconds and <span class='Gold-span'>Minor Force</span> for 36 seconds, increasing your Movement Speed by 30% and Critical Damage by 10%.",
    "effect_2": "Triples the duration, but adds a cast time.",
    "icon": "https://mainbuilds.com/media/skills/guild/psijic-order/accelerate/channeled-acceleration.png",
}
/*End Psijic Order:*/

/*Start Support:*/
export const LingeringFlare = {
    "name": "Lingering Flare",
    "effect_1": "Launch a blinding flare, revealing stealthed and invisible enemies in the target area for 10 seconds. Exposed enemies cannot return to stealth or invisibility for 4 seconds. While slotted you gain <span class='Gold-span'>Major Protection</span>, reducing your damage taken by 10%.",
    "effect_2": "The flare lasts longer.",
    "icon": "https://mainbuilds.com/media/skills/alliance-war/support/revealing-flare/lingering_flare.png",
}
/*End Support:*/

/*Start Undaunted:*/
export const EnergyOrb = {
    "name": "Energy Orb",
    "effect_1": "Project a globe of regeneration that slowly floats forward, healing for 489 Health every 1 second to you and nearby allies. An ally near the globe can activate the Healing Combustion synergy, causing the orb to explode and heal for 2249 Health to nearby allies and restoring 3960 Magicka or Stamina to the activator, whichever maximum is higher.",
    "effect_2": "The orb heals allies instead of damaging enemies and moves faster.",
    "icon": "https://mainbuilds.com/media/skills/guild/undaunted/necrotic-orb/energy-orb.png",
}
export const OverflowingAltar = {
    "name": "Overflowing Altar",
    "effect_1": "Sacrifice your life essence to conjure a fountain of blood to apply <span class='Gold-span'>Minor Lifesteal</span> to enemies in the area, healing you and your allies for 600 Health every 1 second when damaging them. Allies in the area can activate the Blood Feast synergy, healing for 65% of their Max Health.",
    "effect_2": "The synergy heals allies for more. Reduces the cost as the ability ranks up.",
    "icon": "https://mainbuilds.com/media/skills/guild/undaunted/blood-altar/overflowing-altar.png",
}
/*End Undaunted:*/

/*Start Assault:*/
export const ResolvingVigor = {
    "name": "Resolving Vigor",
    "effect_1": "Let loose a battle cry, instilling yourself with resolve and healing for 5388 Health over 5 seconds. After casting you gain <span class='Gold-span'>Minor Resolve</span>, increasing your Physical and Spell Resistance by 2974, for 20 seconds.",
    "effect_2": "The heal over time now happens faster over a shorter duration, heals for more, increases your Armor, but now only targets yourself.",
    "icon": "https://mainbuilds.com/media/skills/alliance-war/assault/vigor/resolving-vigor.png",
}
/*End Assault:*/