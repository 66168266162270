import React from "react";

class Discord extends React.Component {
    render() {
        return (
            <div className="Content-div">
                <div className="PvP-div">
                    <fieldset className="PvP-fieldset">
                        <legend className="PvP-legend">
                            <h1 className="PvP-h1">PvP Discords</h1>
                        </legend>
                        <ul className="Discord-ul">
                            <li className="EPA-li">
                                <a className="Discord-a" href="https://discord.com/invite/3wVKerwUfM" target="_blank" rel="noopener noreferrer"><span className="Discord-span">ESO PvP Academy</span></a>
                            </li>
                        </ul>
                        <br></br>
                        <ul className="Discord-ul">
                            <li className="HC-li">
                                <a className="Discord-a" href="https://discord.com/invite/SUrzdT7xVC" target="_blank" rel="noopener noreferrer"><span className="Discord-span">HoarcruxYT PvP</span></a>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <div className="PvE-div">
                    <fieldset className="PvE-fieldset">
                        <legend className="PvE-legend">
                            <h1 className="PvE-h1">PvE Discords</h1>
                        </legend>
                        <ul className="Discord-ul">
                            <li className="TD-li">
                                <a className="Discord-a" href="https://discord.gg/totaldarkness" target="_blank" rel="noopener noreferrer"><span className="Discord-span">Total Darkness</span></a>
                            </li>
                        </ul>
                        <br></br>
                        <ul className="Discord-ul">
                            <li className="Unify-li">
                                <a className="Discord-a" href="https://discord.gg/unify" target="_blank" rel="noopener noreferrer"><span className="Discord-span">Unify</span></a>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div >
        )
    }
}

export default Discord;