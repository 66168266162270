import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div className="Introduction-div">
        <p className="Home-p">This website seamlessly blends my love for coding with my enthusiasm for the Elder Scrolls Online (ESO). Explore the builds that my friends and I employ; remember, these are merely suggestions, and the ultimate goal of the game is to have fun!</p>
      </div>
    );
  }
}

export default Home;